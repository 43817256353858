import axios from '@/utils/request';

const getUserReport = function(params) {
  return axios.get('/shareit/pgc/report/getUserReport', { params: { ...params }});
};
/**
 * 获取用户视频信息列表
 * @param {*} params
 */
const getUseReportDetail = function(params) {
  return axios.get('/shareit/pgc/report/getUseReportDetail', { params: { ...params }});
};
const getDetailList = function(params) {
  return axios.get('/shareit/pgc/report/getDetailList', { params: { ...params }});
};
export {
  getUserReport,
  getUseReportDetail,
  getDetailList
};
/**
 * 获取用户视频信息概览
 * @param {*} params
 */
export const getDayDetailList = function(params) {
  return axios.get('/shareit/pgc/report/getUserDayReport', { params: { ...params }});
};
