import axios from '@/utils/request';

/**
 * @name 分页获取任务列表
 * @param {*} params
 */
export function getTask(params) {
  return axios.get('/shareit/pgc/tasks/getTasks', { params: { ...params }});
}
/**
 * @name 生成用户任务
 */
export function generateTask() {
  return axios.get('/shareit/pgc/tasks/generateTasks');
}
/**
 * @name 任务生成状态
 */
export function getTaskStatus() {
  return axios.get('/shareit/pgc/tasks/getTaskStatus');
}
