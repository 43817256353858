<!--  chenyh1 -->
<template>
  <div class="task-item">
    <div class="title">{{ task.taskName }}</div>
    <div class="data">
      <span class="day">Due：{{ task.day }} day</span>
      <span>Today's upload limit：{{ task.remainNum === -1 ? 'unlimited' : task.remainNum }}</span>
    </div>
    <div class="handle">
      <ul class="case">
        <template v-for="(item,idx) in task.cases">
          <li v-if="idx < 3" :key="item.itemId" @click="clickCase(item)">Case{{ idx+1 }}</li>
        </template>
      </ul>
      <router-link v-if="task.remainNum !== 0" :to="uploadRoute" class="upload-btn link_blue">Upload>></router-link>
    </div>
    <el-dialog :visible.sync="visible" :show-close="false" custom-class="video-dialog" width="722px" @closed="dialogClosed">
      <div v-show="videoId" class="video-item">
        <youtube ref="youtube" :width="722" :height="406" :video-id="videoId" :player-vars="playerVars"></youtube>
      </div>
      <div v-show="!videoId" class="video-item">
        <video-player
          v-if="playerOptions.sources[0].src"
          ref="videoPlayer"
          :options="playerOptions"
          :playsinline="true"
          class="video-player-box"
          @play="onPlayerPlay"
        >
        </video-player>
        <div v-show="!videoPlayStatus" class="video-title">
          <div class="text">{{ videoTitle }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player';
import { Youtube, getIdFromUrl } from 'vue-youtube';
import '@/assets/styles/video.scss';
export default {
  components: { videoPlayer, Youtube },
  props: {
    task: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      visible: false,
      playerOptions: {
        width: '722',
        height: '406',
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: 'video/mp4',
          // mp4
          src: ''
        }],
        poster: ''
      },
      videoTitle: '',
      videoPlayStatus: false,
      playerVars: {
        autoplay: 0,
        iv_load_policy: 3,
        rel: 0
      },
      videoId: ''
    };
  },
  computed: {
    uploadRoute() {
      return {
        path: '/post',
        query: {
          taskId: this.task.taskId,
          label1: this.task.label1,
          label2: this.task.label2,
          label3: this.task.label3,
          topics: this.task.topics.join(','),
          entities: this.task.entities.join(',')
        }
      };
    }
  },
  methods: {
    isYoutube(url) {
      return /youtube/.test((url || '').toLowerCase());
    },
    clickCase(item) {
      if (this.isYoutube(item.videoUrl)) {
        this.videoId = getIdFromUrl(item.videoUrl);
        this.playerOptions.sources[0].src = '';
        this.playerOptions.poster = '';
        this.videoTitle = '';
      } else {
        this.videoId = '';
        this.playerOptions.sources[0].src = item.videoUrl;
        this.playerOptions.poster = item.imgUrl;
        this.videoTitle = item.title || '';
      }
      this.visible = true;
    },
    onPlayerPlay() {
      this.videoPlayStatus = true;
    },
    dialogClosed() {
      if (this.videoId) {
        this.$refs.youtube.player.pauseVideo();
      } else {
        this.$refs.videoPlayer.player.pause();
      }
    }
  }
};

</script>
<style lang='scss' rel='stylesheet/sass' scoped>
@import '~@/assets/styles/variables.scss';
.task-item {
  width: calc(50% - 5px);
  display: inline-block;
  box-sizing: border-box;
  margin-bottom: 10px;
  &:nth-of-type(odd) {
    margin-right: 10px;
  }
  min-height: 50px;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  padding: 8px 10px;
  font-size: 14px;
  color: #727272;
  font-family: PingFangSC-Regular, sans-serif;
  .title {
    line-height: 30px;
    color: #4A4A4A;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: PingFangSC-Semibold, sans-serif;
  }
  .data {
    line-height: 26px;
    .day {
      margin-right: 30px;
    }
  }
  .handle {
    line-height: 26px;
    color: $--color-primary;
    .case {
      display: inline-block;
      &>li {
        display: inline;
        margin-right: 15px;
        cursor: pointer;
      }
    }
    .upload-btn {
      float: right;
      // color: $--color-primary;
    }
  }
}
.video-dialog {
  .video-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-image: linear-gradient(180deg,transparent,rgba(0,0,0,.7) 80%);
    padding: 20px 10px 10px;
    box-sizing: border-box;
    .text {
      color: #fff;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-size: 16px;
      line-height: 1.5;
      max-height: 48px;
    }
  }
  .video-item {
    height: 406px;
    background: #000;
  }
}
</style>
<style lang="scss" rel='stylesheet/sass'>
.video-dialog {
  .el-dialog__body {
    padding: 0;
  }
}
</style>
