<template>
  <div v-if="loading || dataList.length > 0" class="dash_wrapper">
    <div class="dash">
      <div v-loading="loading" class="dash-content">
        <div class="dash-title">
          {{ $t('medal.medal') }}
          <router-link to="/medal" class="more">{{ $t('home.more') }} ></router-link>
        </div>
        <div class="dash-info">
          <img v-for="item in dataList" :key="item.id" class="item" :src="item.imageOff" alt="" @click="$router.push('/medal')">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { medalList } from '@/views/medal/api/index';

export default {
  name: 'DashContent',
  data() {
    return {
      loading: true,
      dataList: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.loading = true;
      medalList().then(res => {
        res = res.data;
        if (res.code === 0) {
          this.dataList = res.data;
        }
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
.dash_wrapper {
  width: 100%;
}
.dash {
  background: #ffffff;
  border-radius: 6px;
  font-family: Roboto-Bold;
}
.dash-content {
  overflow: hidden;
  .dash-title {
    height: 52px;
    line-height: 52px;
    margin: 8px 22px 0;
    font-size: 16px;
    color: #4a4a4a;
    display: flex;
    justify-content: space-between;
    .more {
      font-family: Roboto-Regular;
      font-size: 12px;
      color: #949494;
    }
  }
  .dash-info {
    padding: 0 26px 10px;
    display: flex;
    flex-wrap: wrap;
    min-height: 50px;
    .item {
      width: 40px;
      height: 40px;
      margin-right: 16px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
}
</style>
