import { render, staticRenderFns } from "./dashBoard.vue?vue&type=template&id=4ec1c37b&scoped=true&"
import script from "./dashBoard.vue?vue&type=script&charset=utf-8&lang=js&"
export * from "./dashBoard.vue?vue&type=script&charset=utf-8&lang=js&"
import style0 from "./dashBoard.vue?vue&type=style&index=0&id=4ec1c37b&lang=scss&rel=stylesheet%2Fsass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ec1c37b",
  null
  
)

export default component.exports