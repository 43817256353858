<template>
  <div class="dash_wrapper">
    <div class="dash">
      <div v-loading="loading" class="dash-content">
        <div class="dash-title">{{ $t('home.bulletin') }}</div>
        <ol class="dash-info">
          <template v-for="(item,index) in boardList">
            <li :key="index" class="dash-item" @click="detail(item)">
              <div class="title">
                {{ item.title }}
              </div>
              <span class="time">{{ item.updateTime | timeFormat('yyyy-MM-dd') }}</span>
            </li>
          </template>
        </ol>
        <el-pagination :total="total" :current-page.sync="page.pageNo" :page-size="page.pageSize" background layout="->, prev, pager, next" @current-change="initData">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { noticeList } from '@/api/home';
export default {
  name: 'DashContent',
  data() {
    return {
      loading: true,
      boardList: [],
      total: 0,
      page: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.loading = true;
      noticeList(this.page).then(res => {
        res = res.data;
        if (res.code === 0) {
          this.boardList = res.data.list;
          this.total = res.data.total;
        }
        this.loading = false;
      });
    },
    detail(item) {
      this.$report(`/wm_home/bulletin/x`, {
        id: item.id
      });
      if (item.category === 'system') {
        this.$router.push({ path: '/notice/' + item.id });
      } else if (item.category === 'activity') {
        window.open(item.actionUrl);
      }
    }
  }
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
.dash_wrapper {
  width: 100%;
  margin-bottom: 0 !important;
}
.dash {
  background: #ffffff;
  border-radius: 6px;
  font-family: Roboto-Bold;
}
.dash-content {
  overflow: hidden;
  .dash-title {
    height: 52px;
    line-height: 52px;
    margin: 8px 22px 0;
    font-size: 16px;
    color: #4a4a4a;
    letter-spacing: 0;
    border-bottom: 1px solid #E6E6E6;
  }
  .dash-info {
    padding: 7px 10px;
    .dash-item {
      height: 50px;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 12px;
      cursor: pointer;
      overflow: hidden;
      .title {
        flex: 1;
        font-size: 14px;
        color: #4a4a4a;
        @include ellipsis;
      }
      .time {
        width: 100px;
        font-family: Roboto-Regular;
        font-size: 12px;
        color: #949494;
        text-align: right;
      }
      &:hover {
        background: #f5f5f5;
      }
    }
  }
  .el-pagination {
    padding-right: 17px;
    padding-top: 13px;
  }
}
</style>
