<template>
  <div class="home-wrapper">
    <st-dashBoard class="m-b-20"></st-dashBoard>
    <st-dash-task class="m-b-20"></st-dash-task>
    <div class="wrapper-inline">
      <div class="inline-left" :class="{long: academyCount === 0 && activityCount === 0}">
        <st-dashAd class="m-b-20"></st-dashAd>
        <st-dashMedal class="m-b-20"></st-dashMedal>
        <st-dashContent class="m-b-20"></st-dashContent>
      </div>
      <div v-if="academyCount > 0 || activityCount > 0" class="inline-right">
        <st-dashActivity class="m-b-20" :show-count="academyCount > 3 ? 3 : 6 - academyCount" @contentCount="(val)=> activityCount = val"></st-dashActivity>
        <st-dashAcademy class="m-b-20" :show-count="activityCount > 3 ? 3 : 6 - activityCount" :is-guide="guideModal && guideList[0] === 'academy'" @contentCount="(val)=> academyCount = val"></st-dashAcademy>
      </div>
    </div>
    <st-original-form ref="originalForm" type="guide" @success="cancelOriginal" @cancel="cancelOriginal"></st-original-form>
  </div>
</template>

<script type="text/ecmascript-6" charset="utf-8">

import dashBoard from './dashBoard';
import dashAd from './dashAd';
import dashContent from './dashContent';
import dashTask from './dashTask';
import dashAcademy from './dashAcademy';
import dashActivity from './dashActivity';
import dashMedal from './dashMedal';
import { mapActions, mapState } from 'vuex';
import StOriginalForm from '@/views/functions/items/components/origianlForm'

export default {
  name: 'StHome',
  components: {
    'st-dashBoard': dashBoard,
    'st-dashAd': dashAd,
    'st-dashContent': dashContent,
    'stDashTask': dashTask,
    'stDashAcademy': dashAcademy,
    'StOriginalForm': StOriginalForm,
    'StDashActivity': dashActivity,
    'StDashMedal': dashMedal
  },
  data() {
    return {
      academyCount: 3,
      activityCount: 3
    };
  },
  computed: {
    ...mapState('guide', ['guideModal', 'guideList'])
  },
  watch: {
    guideList: {
      handler: function(val) {
        this.initOriginal();
      }
    }
  },
  mounted() {
    this.initOriginal();
  },
  methods: {
    ...mapActions('guide', ['setGuideModal', 'saveCurrentGuide']),
    initOriginal() {
      if (this.guideList[0] === 'original') {
        this.$refs.originalForm.show();
      }
    },
    cancelOriginal() {
      this.saveCurrentGuide('original')
    }
  }
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
.home-wrapper {
  width: 950px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.wrapper-inline {
  display: flex;
  .inline-left {
    flex: 1;
    width: calc(100% - 370px);
    &.long {
      width: 100%;
    }
  }
  .inline-right {
    width: 350px;
    margin-left: 20px;
  }
}
</style>
