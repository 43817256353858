import axios from '@/utils/request';

/**
 * @name 分页
 */
export const pageActivity = (params) => {
  return axios.post('/shareit/pgc/activity/list', { ...params })
}

/**
 * @name 投稿类活动列表
 */
export const postActivity = () => {
  return axios.post('/shareit/pgc/activity/list/type/theme')
}

/**
 * @name 详情
 */
export const activityDetail = (params) => {
  return axios.post('/shareit/pgc/activity/get', { ...params })
}

/**
 * @name 参与活动
 */
export const joinActivity = (params) => {
  return axios.post('/shareit/pgc/activity/join', { ...params })
}

/**
 * @name 参与活动状态
 */
export const joinStatus = (params) => {
  return axios.post('/shareit/pgc/activity/getJoinStatus', { ...params })
}
