<template>
  <div v-if="dataList.length > 0" class="dash_wrapper">
    <div class="dash">
      <div v-loading="loading" class="dash-content">
        <div class="dash-title">
          {{ $t('home.creator_event') }}
          <router-link to="/activity" class="more" @click.native="handleMore">{{ $t('home.more') }} ></router-link>
        </div>
        <div class="dash-info">
          <template v-for="(item,index) in dataList">
            <div v-if="index < showCount" :key="index" class="dash-item" @click="handleDetail(item)">
              <div class="cover">
                <img class="img" :src="item.posterUrl" alt="">
              </div>
              <div class="message">
                <div class="title">
                  <div class="content">
                    {{ item.name }}
                  </div>
                  <svg-icon v-if="item.hot" class="hot" icon-class="hot"></svg-icon>
                </div>
                <div class="remain">End in: {{ endDay(item.endTimestamp) }} days</div>
                <div class="views">
                  <template v-if="item.totalAmount">
                    <span class="bonus">Reward: <span class="num">${{ item.totalAmount | numberToK }}</span></span> ·
                  </template>
                  <span>{{ (item.memberCount || 0) | numberToK }} joined</span>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pageActivity } from '@/api/activity';
import { mapGetters } from 'vuex';
export default {
  name: 'DashActivity',
  props: {
    isGuide: {
      type: Boolean,
      default: false
    },
    showCount: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      loading: true,
      dataList: [{}, {}, {}]
    };
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  created() {
    if (this.userInfo && this.userInfo.level >= 0 && this.userInfo.level <= 9 && this.userInfo.country === 'ID') {
      this.initData();
    } else {
      this.dataList = [];
      this.$emit('contentCount', 0)
    }
  },
  methods: {
    initData() {
      this.loading = true;
      pageActivity({ progressStatus: 2 }).then(res => {
        res = res.data;
        if (res.code === 0) {
          this.dataList = res.data.list || [];
          this.$emit('contentCount', this.dataList.length)
        } else {
          this.$emit('contentCount', 0)
        }
        this.loading = false;
      });
    },
    handleDetail(item) {
      this.$report(`/wm_activity/list/activity`, {
        aid: item.activityId
      });
      const url = item.pubUrl + '?referrer=' + this.encrypt(encodeURIComponent(JSON.stringify({ uc: 'activity', us: 'WeMedia', um: JSON.stringify({ activityId: item.activityId, inviteUserId: this.userInfo.userid || '' }) })))
      window.open(url);
    },
    endDay(time) {
      const day = Math.ceil((time - new Date().getTime()) / 1000 / 3600 / 24);
      return day > 0 ? day : 0;
    },
    handleMore() {
      this.$report(`/wm_home/activity/more`);
    },
    encrypt(val) {
      return btoa(val)
    }
  }
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
.dash {
  background: #ffffff;
  border-radius: 6px;
  font-family: Roboto-Bold;
  position: relative;
}
.dash-content {
  overflow: hidden;
  .dash-title {
    height: 52px;
    line-height: 52px;
    margin: 8px 22px 0;
    font-size: 16px;
    color: #4a4a4a;
    display: flex;
    justify-content: space-between;
    .more {
      font-family: Roboto-Regular;
      font-size: 12px;
      color: #949494;
    }
  }
  .dash-info {
    padding: 7px 10px;
    .dash-item {
      padding: 11px 12px;
      display: flex;
      border-radius: 4px;
      cursor: pointer;
      .cover {
        width: 120px;
        height: 67.8px;
        border-radius: 4px;
        overflow: hidden;
        .img {
          width: 100%;
          height: 100%;
        }
      }
      .message {
        flex: 1;
        margin-left: 14px;
        .title {
          display: flex;
          align-items: center;
          .content {
            max-width: 142px;
            font-family: Roboto-Medium;
            font-size: 14px;
            color: #222222;
            line-height: 18px;
            padding-right: 2px;
            @include ellipsis();
          }
          .hot {
            width: 28px;
            height: 14px;
          }
        }
        .remain {
          margin-top: 20px;
          line-height: 12px;
          font-family: Roboto-Regular;
          font-size: 10px;
          color: #606060;
        }
        .views {
          margin-top: 7px;
          font-family: Roboto-Regular;
          font-size: 10px;
          color: #606060;
          .bonus {
            .num {
              font-family: Roboto-Bold;
              color: #FF4900;
            }
          }
        }
      }
      &:hover {
        background: #f5f5f5;
      }
    }
  }
}
</style>
