import axios from '@/utils/request';

/**
 * banner列表
 */
export function bannerList() {
  return axios.post('/shareit/pgc/home/banner/list');
}

/**
 * notice列表
 */
export function noticeList(params) {
  return axios.post('/shareit/pgc/home/notice/list', params);
}

/**
 * notice详情
 */
export function noticeDetail(params) {
  return axios.post('/shareit/pgc/home/notice/detail', params);
}
