<template>
  <div class="dash_wrapper">
    <div class="user-info">
      <div class="avatar-wrap">
        <img :src="userInfo.avatar || defaultAvatar" class="avatar" alt="" />
        <img
          v-if="medal && medal.imageOn"
          :src="medal.imageOn"
          class="medal"
          alt=""
        />
      </div>
      <div class="info-wrap">
        <div class="user-name">
          <div class="name">{{ userInfo.nickName }}</div>
          <div
            v-if="
              userInfo.country === 'ID' &&
              userInfo.level >= 0 &&
              userInfo.level < 10
            "
            class="level"
            :class="levelClass"
          >
            {{ stage ? "Pro" : "Fresh" }}
          </div>
        </div>
        <div v-if="userInfo.wemediaElite" class="elite-creator">
          <svg-icon icon-class="elite-creator"></svg-icon>WeMedia Elite Creator
        </div>
      </div>
    </div>
    <div class="dash">
      <div v-for="item in dashList" :key="item.id" class="dash-item">
        <p class="title">{{ item.name }}</p>
        <p class="desc">{{ item.count }}</p>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6" charset="utf-8">
import { getUserReport } from "@/api/analytics";
import { mapGetters } from "vuex";
import { userTypeInfo } from "@/api/functions";
import { medalWearing } from "@/views/medal/api/index";
const defaultAvatar = require("@/assets/images/avatar.png");
export default {
  name: "StDashBoard",
  data() {
    return {
      defaultAvatar: defaultAvatar,
      stage: "",
      userReport: {},
      medal: {},
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    dashList: function () {
      return [
        {
          id: "fans",
          name: this.$t("home.fans"),
          count: this.userReport.fansNum,
        },
        {
          id: "recommend",
          name: this.$t("home.recommend"),
          count: this.userReport.recommendsNum,
        },
        {
          id: "clicks",
          name: this.$t("home.clicks"),
          count: this.userReport.playsNum,
        },
      ];
    },
    levelClass() {
      let _class = "";
      switch (this.stage) {
        case 0:
          _class = "fresh";
          break;
        case 1:
          _class = "pro";
          break;
        default:
          break;
      }
      return _class;
    },
  },
  created() {
    this.getReport();
    userTypeInfo().then((res) => {
      res = res.data;
      if (res.code === 0) {
        this.stage = res.data.stage;
      }
    });
    medalWearing().then((res) => {
      res = res.data;
      if (res.code === 0) {
        this.medal = res.data || {};
      }
    });
  },
  methods: {
    getReport() {
      const that = this;
      getUserReport().then((res) => {
        res = res.data;
        if (res.code === 0) {
          that.userReport = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
.dash_wrapper {
  width: 100%;
  padding: 26px;
  height: 142px;
  box-sizing: border-box;
  display: flex;
  background: #fff;
}
.user-info {
  width: 276px;
  display: flex;
  margin-right: 10px;
  .avatar-wrap {
    margin-top: 7px;
    width: 70px;
    height: 70px;
    position: relative;
    .avatar {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    .medal {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: -13px;
    }
  }
  .info-wrap {
    flex: 1;
    margin-left: 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .user-name {
      display: flex;
      align-items: center;
      .name {
        max-width: 140px;
        font-family: Roboto-Medium;
        font-size: 18px;
        color: #222222;
        @include ellipsis();
      }
      .level {
        border: 1px solid #ea6d29;
        color: #ea6d29;
        border-radius: 2px;
        line-height: 16px;
        font-family: Roboto-Medium;
        font-size: 12px;
        width: 40px;
        text-align: center;
        margin-left: 6px;
        &.pro {
          border-color: #ce4d4c;
          color: #ce4d4c;
        }
        &.fresh {
          border-color: #ea6d29;
          color: #ea6d29;
        }
      }
    }
    .elite-creator {
      display: flex;
      align-items: flex-end;
      font-family: Roboto-Regular;
      font-size: 12px;
      color: #606060;
      .svg-icon {
        font-size: 16px;
        margin-right: 4px;
      }
    }
  }
}
.dash {
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  box-sizing: border-box;
  .dash-item {
    background: #f7f8f9;
    border-radius: 6px;
    font-family: Roboto-Regular;
    padding: 21px 20px;
    flex: 1;
    margin-left: 14px;
    .title {
      font-size: 12px;
      color: #606060;
      line-height: 14px;
    }
    .desc {
      font-family: Roboto-Bold;
      padding-top: 9px;
      font-size: 22px;
      color: #222222;
    }
  }
}
</style>
