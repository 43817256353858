<!--  chenyh1 -->
<template>
  <div>
    <!-- success dialog -->
    <el-dialog :visible.sync="successDialog" :before-close="applySuccess" title="Operation Tips" class="dialog" width="450px" top="30vh">
      <div class="msg" v-html="$t('functions.original_label2')"></div>
      <el-button class="success-btn" type="primary" @click="applySuccess">{{ $t('functions.i_know') }}</el-button>
    </el-dialog>
    <!-- apply dialog -->
    <el-dialog :visible.sync="applyDialog" :title="$t('functions.original_label3')" class="dialog" width="510px" top="15vh" :before-close="handleCancel">
      <div v-loading="applyLoading" class="apply-inner">
        <div class="msg">
          <p v-if="type && type === 'guide'" class="p" v-html="$t('functions.original_label4')"></p>
          <div v-html="$t('functions.original_label5')"></div>
        </div>
        <st-content></st-content>
        <div class="instructions">
          <el-checkbox v-model="check" @change="showError = false"></el-checkbox>
          <span v-html="$t('functions.original_label6')"></span>
          <p v-show="showError" class="error">{{ $t('functions.original_label7') }}</p>
        </div>
        <div class="btn">
          <el-button type="primary" @click="handleSubmit">{{ $t('common.submit') }}</el-button>
          <el-button @click="handleCancel">{{ $t('common.cancel') }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { originApply } from '@/api/functions';
import StContent from '@/views/settings/certification/components/index';
export default {
  components: { StContent },
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      successDialog: false,
      applyLoading: false,
      applyDialog: false,
      check: false,
      showError: false
    };
  },
  methods: {
    show() {
      this.applyDialog = true;
    },
    handleSubmit() {
      if (this.check) {
        this.applyLoading = true;
        originApply().then(res => {
          res = res.data;
          if (res.code === 0) {
            this.successDialog = true;
            this.$nextTick(() => {
              this.handleCancel();
            })
          } else {
            this.$message({
              center: true,
              type: 'error',
              message: res.message || 'The application failed.'
            });
          }
          this.applyLoading = false;
        });
      } else {
        this.showError = true;
      }
    },
    handleCancel() {
      this.check = false;
      this.applyDialog = false;
      this.$emit('cancel')
    },
    applySuccess() {
      this.successDialog = false;
      this.$emit('success')
    }
  }
}

</script>
<style lang='scss' rel='stylesheet/sass' scoped>
.dialog {
  .success-btn {
    margin-top: 10px;
  }
  .apply-inner {
    text-align: left;
    .msg {
      line-height: 20px;
      margin-bottom: 13px;
      font-family: Roboto-Regular;
      font-size: 12px;
      color: #4A4A4A;
      word-break: break-word;
      ::v-deep {
        .p {
          font-size: 14px;
          line-height: 26px;
          .mark {
            color: #EE1A1A;
          }
        }
      }
    }
    .instructions {
      ::v-deep {
        a {
          font-family: Roboto-Regular;
          font-size: 12px;
          color: #0092FF;
        }
        .error {
          font-family: Roboto-Regular;
          font-size: 12px;
          color: #EE1A1A;
          line-height: 1;
        }
      }
    }
    .btn {
      text-align: center;
      margin-top: 10px;
      margin-top: 30px;
      .el-button {
        width: 86px;
        line-height: 36px;
        padding: 0;
        font-family: Roboto-Bold;
        font-size: 14px;
      }
    }
  }
}
</style>
