<template>
  <div v-if="courseList.length > 0" class="dash_wrapper">
    <div class="dash" :class="{'is-guide': isGuide}">
      <div v-loading="loading" class="dash-content">
        <div class="dash-title">
          {{ $t('home.recommended') }}
          <router-link to="/academy" class="more">{{ $t('home.more') }} ></router-link>
        </div>
        <div class="dash-info">
          <template v-for="(item,index) in courseList">
            <div v-if="index < showCount" :key="index" class="dash-item" @click="handleDetail(item)">
              <div class="cover">
                <img class="img" :src="item.imgUrl" alt="">
              </div>
              <div class="message">
                <div class="title" v-html="item.title"></div>
                <div class="views"><span class="num">{{ item.clicks }}</span> Clicks</div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { homeCourse } from '@/api/academy';
export default {
  name: 'DashCourse',
  props: {
    isGuide: {
      type: Boolean,
      default: false
    },
    showCount: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      loading: true,
      courseList: [{}, {}, {}]
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.loading = true;
      homeCourse().then(res => {
        res = res.data;
        if (res.code === 0) {
          this.courseList = res.data;
          this.$emit('contentCount', this.courseList.length)
        }
        this.loading = false;
      });
    },
    handleDetail(item) {
      window.open(`/academy/${item.itemId}`);
    }
  }
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
.dash_wrapper {
  margin-bottom: 0 !important;
}
.dash {
  background: #ffffff;
  border-radius: 6px;
  font-family: Roboto-Bold;
  position: relative;
  &.is-guide {
    z-index: 10000;
    &::after {
      content: "";
      position: absolute;
      border: 1px dashed #ffffff;
      left: -4px;
      top: -4px;
      right: -4px;
      bottom: -4px;
      box-sizing: border-box;
      border-radius: 6px;
    }
  }
}
.dash-content {
  overflow: hidden;
  .dash-title {
    height: 52px;
    line-height: 52px;
    margin: 8px 22px 0;
    font-size: 16px;
    color: #4a4a4a;
    display: flex;
    justify-content: space-between;
    .more {
      font-family: Roboto-Regular;
      font-size: 12px;
      color: #949494;
    }
  }
  .dash-info {
    padding: 7px 10px;
    .dash-item {
      padding: 11px 12px;
      display: flex;
      border-radius: 4px;
      cursor: pointer;
      .cover {
        width: 120px;
        height: 67.8px;
        border-radius: 4px;
        overflow: hidden;
        .img {
          width: 100%;
          height: 100%;
        }
      }
      .message {
        margin-left: 14px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        .title {
          width: 172px;
          @include ellipsis-ln(2);
          font-size: 12px;
          color: #4A4A4A;
          line-height: 18px;
        }
        .views {
          font-family: Roboto-Regular;
          font-size: 10px;
          color: #949494;
          .num {
            color: #4A4A4A;
          }
        }
      }
      &:hover {
        background: #f5f5f5;
      }
    }
  }
}
</style>
