import axios from '@/utils/request';

/**
 * @name 分页
 */
export const pageCourse = (params) => {
  return axios.post('/shareit/pgc/course/list', { ...params })
}

/**
 * @name 详情
 */
export const courseDetail = (params) => {
  return axios.get('/shareit/pgc/course/id', { params: { ...params }})
}

/**
 * @name 首页课程
 */
export const homeCourse = () => {
  return axios.get('/shareit/pgc/course/home')
}
