import axios from '@/utils/request';

/**
 * @name 勋章列表
 * @returns
 */
export function medalList() {
  return axios.post('/shareit/pgc/medal/get')
}

/**
 * @name 当前穿戴的勋章
 * @returns
 */
export function medalWearing() {
  return axios.post('/shareit/pgc/medal/wearing')
}

/**
 * @name 勋章操作
 * @returns
 */
export function medalOperate(params) {
  return axios.post('/shareit/pgc/medal/operate', { ...params })
}
