<template>
  <div v-show="taskStatus !== 2" v-loading="loading" class="dash_wrapper">
    <div class="dash">
      <div class="dash-content">
        <div class="dash-title">Tasks
          <div v-show="taskStatus === 1" class="notice">The task is being generated!</div>
          <div class="hadle">
            <el-button type="text" icon="el-icon-refresh" class="refresh" @click="refresh">Refresh</el-button>
            <router-link v-show="taskStatus === 0" to="/task" class="more">More>></router-link>
          </div>
        </div>
        <div v-show="taskStatus === 0" class="line topBorder"></div>
        <div v-show="taskStatus === 0" class="dash-info">
          <st-task v-for="task in tasks" :key="task.taskId" :task="task"></st-task>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stTask from '../task/task';
import { getTask, getTaskStatus } from '@/api/task';
export default {
  name: 'DashTask',
  components: { stTask },
  data() {
    return {
      loading: false,
      tasks: [],
      total: 0,
      params: {
        name: '',
        pageOn: 1,
        pageSize: 6
      },
      taskStatus: 2,
      timeOut: null
    };
  },
  created() {
    // this.getTask();
    this.getTaskStatus();
  },
  methods: {
    getTaskStatus() {
      getTaskStatus().then(res => {
        res = res.data;
        if (res.code === 0) {
          this.taskStatus = res.data;
          if (res.data === 1) {
            this.timeOut = setTimeout(() => {
              this.getTaskStatus();
            }, 5000);
          } else if (res.data === 0) {
            this.getTask();
          }
        }
      });
    },
    getTask() {
      this.loading = true;
      getTask(this.params).then(res => {
        res = res.data;
        if (res.code === 0) {
          this.total = res.data.total;
          this.tasks = res.data.list;
        }
        this.loading = false;
      });
    },
    refresh() {
      if (this.taskStatus === 1) {
        this.timeOut && clearTimeout(this.timeOut);
        this.getTaskStatus();
      } else {
        if (this.params.pageOn * this.params.pageSize < this.total) {
          this.params.pageOn++;
        } else {
          this.params.pageOn = 1;
        }
        this.getTask();
      }
    }
  }
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
@import "~@/assets/styles/variables.scss";
.dash_wrapper {
  width: 100%;
  .dash {
    background: #ffffff;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    font-family: PingFangSC-Semibold, sans-serif;
  }
  .dash-content {
    overflow: hidden;
    .dash-title {
      position: relative;
      height: 42px;
      line-height: 28px;
      padding: 18px 22px 0 ;
      font-size: 20px;
      color: #4a4a4a;
      letter-spacing: 0;
      .hadle {
        float: right;
        font-family: PingFangSC-Regular, sans-serif;
        font-size: 16px;
        .refresh {
          padding: 0;
          font-size: 16px;
        }
        .more {
          color: $--color-primary;
        }
      }
      .notice {
        font-size: 14px;
        color: #ee1a1a;
        position: absolute;
        top: 18px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .line {
      margin: 0 30px;
      &.topBorder {
        border-top: 1px solid #e6e6e6;
      }
    }
    .dash-info {
      padding: 10px 30px;
      font-size: 0;
    }
  }
}
</style>
