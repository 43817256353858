<template>
  <div v-if="loading || banners.length > 0" class="dash_wrapper">
    <div v-loading="loading" class="dash-img">
      <el-carousel height="130px" indicator-position="none" autoplay>
        <el-carousel-item v-for="(item, idx) in banners" :key="idx">
          <a :href="item.actionUrl" target="_blank" @click="handleClick(item)">
            <img :src="item.imageUrl" class="imgAd" alt="banner">
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { bannerList } from '@/api/home';
export default {
  name: 'DashAd',
  data() {
    return {
      loading: true,
      banners: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.loading = true;
      bannerList().then(res => {
        res = res.data;
        if (res.code === 0) {
          this.banners = res.data;
        }
        this.loading = false;
      });
    },
    handleClick(item) {
      this.$report(`/wm_home/banner/x`, {
        banner_url: item.actionUrl
      });
    }
  }
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
.dash_wrapper {
  width: 100%;
  background-color: #ffffff;
  border-radius: 6px;
}
.dash-img {
  // width: 950px;
  height: 130px;
  .el-carousel {
    border-radius: 6px;
  }
  .imgAd {
    width: 100%;
    height: 100%;
  }
}
</style>
